@media all and (min-width: 300px) {
  .about-container {
    width: 100%;
    height: 100vh;
  }
  .about-title {
    background-color: rgba(255, 255, 255, 0.2);
    height: 55.5625px;
    font-weight: normal;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gold;
  }
  .description {
    color: white;
    padding: 20px 10px 10px 10px;
    text-align: center;
    font-size: 1.2rem;
  }
  .education-wrapper {
    padding: 20px 10px;
  }
  .education-title {
    color: Gold;
    /* padding-left: 10px; */
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
    text-decoration: underline grey;
  }
  .education-container {
    padding: 20px 10px;
    font-size: 1.2rem;
  }
  .education {
    color: Gold;
  }
  .uni {
    color: white;
  }
}
@media all and (min-width: 600px) {
  .about-title {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1.5rem;
    padding: 50px 10px;
  }
  .education-wrapper {
    padding: 50px 10px;
  }

  .education-title {
    font-size: 2rem;
  }
  .education-container {
    font-size: 1.5rem;
  }
}
