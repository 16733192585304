@media all and (min-width: 300px) {
  .Skills-container {
    width: 100%;
    height: 100vh;
  }
  .skills-title {
    background-color: rgba(255, 255, 255, 0.2);
    height: 55.5625px;
    font-weight: normal;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gold;
  }
  .skills-description {
    color: white;
    padding: 20px 10px 10px 10px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: normal;
  }
  .Skills-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .Skills-listItem {
    list-style: none;
    padding: 10px 0px;

    margin: 20px;
  }
  .Skills-image {
    width: 70px;
    height: 70px;
  }
  .SkillsImage-name {
    color: Gold;
    display: flex;
    justify-content: center;
  }
  .tools-title {
    color: Gold;
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
    text-decoration: underline grey;
  }
  .tools-list {
    padding: 20px 10px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .tools-listItem {
    list-style: none;
    padding: 10px 0px;
  }
  .tool-image {
    width: 100px;
    height: 100px;
  }
  .toolImage-name {
    color: Gold;
    display: flex;
    justify-content: center;
  }
}
@media all and (min-width: 600px) {
  .skills-title {
    font-size: 2.5rem;
  }
  .skills-description {
    margin: 30px 0px 20px 0px;

    font-size: 1.5rem;
  }
  .tools-wrapper {
    margin-top: -50px;
    padding: 50px 10px;
  }
  .tools-title {
    font-size: 2rem;
  }
  .tools-list {
    justify-content: center;
  }
  .tools-listItem {
    padding: 0px 50px;
  }
}
