* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  /* font-family: "Mate SC", serif; */
}
.body {
  overflow-x: hidden;
}
#root {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
