@media all and (min-width: 300px) {
  .projects-container {
    margin-top: 520px;
    width: 100%;
    height: 100vh;
  }
  .projects-title {
    background-color: rgba(255, 255, 255, 0.2);
    height: 55.5625px;
    font-weight: normal;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gold;
  }
  .projects-description {
    color: white;
    padding: 20px 10px;
    text-align: center;
    font-size: 1.2rem;
  }
  .projects-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .amazon {
    width: 90%;
    height: 330px;
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.15);
    position: relative;
  }
  .budget {
    width: 90%;
    height: 330px;
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.15);
    position: relative;
  }
  .music {
    width: 90%;
    height: 330px;
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.15);
    position: relative;
  }
  .amazon-image,
  .budgetTracker-image,
  .musicPlayer-image {
    position: absolute;
    object-fit: cover;
    opacity: 0.1;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .amazon-title,
  .budget-title,
  .music-title {
    color: gold;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
  }

  .about-project {
    color: white;
    font-size: 1.02rem;
    font-weight: lighter;
    padding: 0px 10px;
    text-align: center;
  }
  .amazon-stack {
    color: gold;
    padding: 0px 10px;
    text-align: center;
    font-size: 0.8rem;
  }
  .stack {
    color: gold;
    padding: 0px 10px;
    text-align: center;
    font-size: 1.1rem;
  }
  .amazon-button-container,
  .budget-button-container,
  .music-button-container {
    margin-top: 45px;
    display: flex;
    justify-content: space-around;
  }

  .project-buttons {
    font-family: inherit;
    height: 50px;
    width: 120px;
    background-color: rgb(1, 1, 32);
    color: gold;
    font-size: 1.2rem;
    padding: 5px;
    border-radius: 5px;
    border: rgb(1, 1, 32);
  }
}
@media all and (min-width: 600px) {
  .projects-container {
    margin-top: 100px;
  }
  .projects-title {
    font-size: 2.5rem;
  }
  .projects-description {
    margin: 30px 0px 20px 0px;
    font-size: 1.5rem;
  }
  .amazon,
  .budget,
  .music {
    width: 50%;
  }
  .amazon-button-container,
  .budget-button-container,
  .music-button-container {
    margin-top: 75px;
  }

  .about-project {
    font-size: 1.2rem;
  }
  .amazon-stack {
    font-size: 1rem;
  }
  .stack {
    font-size: 1.3rem;
  }
}
