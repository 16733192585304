@media all and (min-width: 300px) {
  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    position: fixed;
    width: 100%;
    padding: 10px 0px;
    display: flex;
    z-index: 3;

    /* background: linear-gradient(45deg, pink, rgb(78, 78, 173)); */
    /* background-color: gold; */
    /* backdrop-filter: blur(5px); */
    /* background: linear-gradient(to bottom, gold 30%, rgb(136, 121, 37) 70%); */

    background-color: gold;
  }
  .nav-container {
    cursor: pointer;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(1, 1, 32);
    margin-top: 9px;
    position: relative;
    /* z-index: 1; */
    z-index: 5;
    font-size: 1.1rem;
  }
  .mob-nav {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    list-style: none;
    transform: translateX(-100%);
    transition: 200ms ease-in;
  }
  .mob-nav-active {
    transform: translateX(0px);
    animation-delay: 3s;
  }
  .nav-items {
    margin-top: 20px;
  }
  .close {
    margin-top: 10px;
    margin-left: 90%;
    color: white;
  }
  .nav-item {
    padding: 30px;
    border-bottom: rgb(1, 1, 32) 1px solid;
    color: white;
    font-weight: normal;
    text-align: center;
  }
  .name-container {
    width: 85%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .name {
    font-weight: bold;
    font-size: 1.08rem;
    /* color: #025858; */
    color: rgb(1, 1, 32);
  }
  .intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    justify-content: space-between;
  }
  .first-line,
  .third-line {
    padding: 20px;
    font-size: 1.195rem;
    font-weight: normal;
    color: #ffffff;
  }
  .second-line {
    font-size: 2rem;
    color: Gold;
  }
  .nav-conLarge {
    display: none;
  }
  .homeImage-container {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
  .home-image {
    width: 30px;
    height: 30px;
    margin: 20px;
  }
}

@media all and (min-width: 600px) {
  .nav-container {
    display: none;
  }
  .nav-conLarge {
    cursor: pointer;
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    color: rgb(1, 1, 32);

    margin-right: 50px;
    font-weight: bold;
    font-size: 1.3rem;
  }

  .nav-itemLarge {
    margin: 10px;
    border-bottom: none;
  }
  .nav-itemLarge:hover {
    border-bottom: white 3px solid;
  }

  .name-container {
    width: 50%;
  }
  .name {
    padding-left: 50px;
    font-size: 1.3rem;
  }
  .imageIntro-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .intro-container {
    margin-top: 250px;
  }
  .first-line,
  .third-line {
    font-size: 1.5rem;
  }
  .second-line {
    font-size: 3rem;
  }
  .home-image {
    width: 50px;
    height: 50px;
    margin: 20px;
  }
}
